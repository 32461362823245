<template>
  <v-card class="elevation-1">
    <v-card-title>
      <span class="mt-4" v-if="formState!==null">{{formState}} Record</span>
      <v-btn
        icon
        small
        class="mt-4"
        text
        @click="formState='New'"
        v-else>
        <v-icon>{{icon.add}}</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form>
        <v-dialog
          ref="dialog"
          v-model="modalStartDate"
          :return-value.sync="startDate"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="start"
              label="Start"
              :append-icon="icon.calendar"
              readonly
              outlined
              dense
              v-bind="attrs"
              v-on="on"
              :class="formErrorFields.startErrorMessage.length > 0 ? 'mb-3' : ''"
              :error-messages="formErrorFields.startErrorMessage"
              :disabled="formState===null"
              @click="formError().remove('start', formErrorFields)"
            ></v-text-field>
          </template>
          <v-date-picker v-model="start" scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modalStartDate=false">
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>

        <v-dialog
          ref="dialog"
          v-model="modalEndDate"
          :return-value.sync="endDate"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="end"
              label="End"
              :append-icon="icon.calendar"
              readonly
              outlined
              dense
              v-bind="attrs"
              v-on="on"
              :class="formErrorFields.endErrorMessage.length > 0 ? 'mb-3' : ''"
              :error-messages="formErrorFields.endErrorMessage"
              :disabled="formState===null"
              @click="formError().remove('end', formErrorFields)"
            ></v-text-field>
          </template>
          <v-date-picker v-model="end" scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modalEndDate=false">
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>

        <v-autocomplete
          v-model="cutoff"
          :items="['1st','2nd']"
          outlined
          dense
          clearable
          label="Cutoff"
          class="mb-3"
          :error-messages="formErrorFields.cutoffErrorMessage"
          :disabled="formState===null"
          @keydown="formError().remove('cutoff', formErrorFields)"
        ></v-autocomplete>

        <v-btn
          color="primary"
          :disabled="formState===null"
          :loading="loading"
          @click="submit">
          Submit
        </v-btn>
        <v-btn
          outlined
          class="mx-2"
          :disabled="formState===null"
          @click="reset"
        >
          Cancel
        </v-btn>
      </v-form>
    </v-card-text>

    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    >
    </snackbar>
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiCalendar, mdiPlusOutline } from '@mdi/js'
import crud from '@/composables/crud'
import snackbar from '@/components/SnackBar.vue'
import formError from '@/composables/formErrorHandler/formError'

export default {
  props: ['data'],
  setup(props, { emit }) {
    const modalStartDate = ref(false)
    const modalEndDate = ref(false)
    const startDate = ref(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 10))
    const endDate = ref(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 10))
    const loading = ref(false)
    const message = ref('')
    const icon = {
      add: mdiPlusOutline,
      calendar: mdiCalendar,
    }
    const start = ref('')
    const end = ref('')
    const cutoff = ref('')
    const formState = ref(null)
    const formErrorFields = ref({
      startErrorMessage: '',
      endErrorMessage: '',
      cutoffErrorMessage: '',
    })

    watch(() => props.data, () => {
      start.value = props.data.start
      end.value = props.data.end
      cutoff.value = props.data.cutoff
      formState.value = 'Edit'
    })

    const {
      store,
      update,
      responseMessage,
      responseMessageStatus,
    } = crud()

    const submit = async () => {
      const formData = {
        start: start.value,
        end: end.value,
        cutoff: cutoff.value,
      }

      if (formState.value === 'New') {
        const uri = `${process.env.VUE_APP_URI}/api/payroll/payroll-periods`
        await store(uri, loading, formData, formErrorFields)
      } else {
        const uri = `${process.env.VUE_APP_URI}/api/payroll/payroll-periods/${props.data.id}`
        await update(uri, loading, formData, formErrorFields)
      }

      message.value = responseMessage.value
      emit('updateUniqueID')
    }

    const reset = () => {
      formState.value = null
      start.value = ''
      end.value = ''
      cutoff.value = ''
    }

    return {
      modalStartDate,
      modalEndDate,
      startDate,
      endDate,
      start,
      end,
      cutoff,
      icon,
      formState,
      reset,
      submit,
      loading,
      message,
      formErrorFields,
      formError,
      responseMessage,
      responseMessageStatus,
    }
  },

  watch: {
    // data(e) {
    //   // console.log(e)
    // },
  },

  components: {
    snackbar,
  },
}
</script>
