<template>
  <v-card class="elevation-1">
    <v-card-title>
      Payroll Periods
      <v-row>
        <v-col cols="12" md="4" lg="4" offset-md="8" offset-lg="8">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="mb-2"
            @keypress.enter="searchTable=search"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="searchTable"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      dense
    >
      <template #[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="activatePayPeriod(item)"
          title="activate"
          v-if="item.activated_at == null"
        >
          {{iconList.activate}}
        </v-icon>

        <v-icon
          small
          class="mr-2"
          @click="closePayPeriod(item)"
          title="close"
          v-if="item.activated_at != null && item.closed_at == null"
        >
          {{iconList.close}}
        </v-icon>

        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
          v-if="item.closed_at == null"
        >
          {{iconList.edit}}
        </v-icon>

        <v-icon
          small
          @click="deleteItem(item)"
          v-if="item.activated_at == null && item.closed_at == null"
        >
          {{iconList.delete}}
        </v-icon>
      </template>
    </v-data-table>

    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    />
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import {
  mdiCheckOutline,
  mdiDeleteOutline,
  mdiLockOutline,
  mdiPencilOutline,
  mdiPlusOutline,
} from '@mdi/js'
import getData from '@/composables/getData'
import crud from '@/composables/crud'
import snackbar from '@/components/SnackBar.vue'

export default {
  props: ['uniqueID'],
  setup(props, { emit }) {
    const search = ref('')
    const headers = ref([
      { text: 'Start From', align: 'start', value: 'start' },
      { text: 'End To', value: 'end' },
      { text: 'Cutoff', value: 'cutoff' },
      { text: 'Active', value: 'status' },
      { text: 'Processed at', value: 'processed_at' },
      { text: 'Processed By', value: 'processedBy.name' },
      { text: 'Closed At', value: 'closed_at' },
      { text: 'Closed By', value: 'closedBy.name' },
      { text: 'Action', value: 'actions' },
    ])
    const items = ref([])
    const totalItems = ref(0)
    const searchTable = ref('')
    const options = ref({})
    const loading = ref(false)
    const iconList = {
      add: mdiPlusOutline,
      edit: mdiPencilOutline,
      delete: mdiDeleteOutline,
      activate: mdiCheckOutline,
      close: mdiLockOutline,
    }

    const {
      remove,
      updateNoData,
      responseMessageStatus,
      responseMessage,
    } = crud()
    const listUri = `${process.env.VUE_APP_URI}/api/payroll/payroll-periods/masterlist`

    watch(options, () => {
      getData(listUri, items, totalItems, loading, options, search)
    })

    watch(searchTable, () => {
      getData(listUri, items, totalItems, loading, options, search)
    })

    watch(() => props.uniqueID, () => {
      getData(listUri, items, totalItems, loading, options, search)
    })

    const editItem = item => {
      emit('passData', item)
    }

    const deleteItem = async item => {
      await remove(`${process.env.VUE_APP_URI}/api/payroll/payroll-periods/${item.id}`, loading)
      await getData(listUri, items, totalItems, loading, options, search)
    }

    const closePayPeriod = async item => {
      const uri = `${process.env.VUE_APP_URI}/api/payroll/payroll-periods/${item.id}/close`
      await updateNoData(uri, loading)
      await getData(listUri, items, totalItems, loading, options, search)
    }

    const activatePayPeriod = async item => {
      const uri = `${process.env.VUE_APP_URI}/api/payroll/payroll-periods/${item.id}/active`
      await updateNoData(uri, loading)
      await getData(listUri, items, totalItems, loading, options, search)
    }

    return {
      search,
      headers,
      items,
      searchTable,
      iconList,
      options,
      totalItems,
      loading,
      editItem,
      deleteItem,
      closePayPeriod,
      activatePayPeriod,
      snackbar,
      responseMessageStatus,
      responseMessage,
    }
  },

  components: {
    snackbar,
  },
}
</script>
